export const certificationTitle = {
  EN: "OUR CORPORATE CERTIFICATIONS",
  IT: "LE NOSTRE CERIFICAZIONI CORPORATE",
  FR: "NOS CERTIFICATIONS D'ENTREPRISE"
};
export const certificationList = [
  {
    title: {
      EN: "FSC CHAIN OF CUSTODY CERTIFICATION",
      IT: "CERTIFICAZIONE DI CATENA DI CUSTODIA FSC",
      FR: "CERTIFICATION DE CHAÎNE DE CONTRÔLE FSC"
    },
    subtitle: {
      EN: `The FSC CoC (Chain of Custody) certification guarantees the traceability of raw material from responsibly managed forests. <br/>
Possession of a valid FSC CoC certification is a necessary condition for marketing a product as certified. Thanks to this protocol, we can guarantee the origin of the raw materials used for the production of our products and demonstrate in a correct, transparent and controlled way our active contribution to responsible forest management.`,
      IT: `La certifcazione CoC (Chain of Custody) FSC garantisce la tracciabilità della materia prima proveniente da foreste gestite responsabilmente. <br/>
Il possesso di una certifcazione CoC FSC in corso di validità è condizione necessaria per poter commercializzare un prodotto come certifcato. Grazie a questo protocollo, possiamo garantire 
la provenienza delle materie prime utilizzate per la produzione dei nostri prodotti e dimostrare in maniera corretta, trasparente e controllata il nostro contributo attivo alla gestione forestale responsabile.`,
      FR: `La certification Chaîne de contrôle (ou CoC pour Chain of Custody) FSC garantit la traçabilité des matières premières provenant de forêts gérées de manière responsable. <br/>
La possession d'une certification CoC FSC en cours de validité est une condition nécessaire pour commercialiser un produit déclaré certifié. Grâce à ce protocole, nous pouvons garantir la provenance des matières premières utilisées pour la production de nos produits et démontrer de manière correcte, transparente et contrôlée notre contribution active à la gestion forestière responsable.`
    },
    coverImage: "LOGO_FSC"
  },
  {
    title: {
      EN: "GRS CERTIFICATION",
      IT: "CERTIFICAZIONE GRS",
      FR: "CERTIFICATION GRS"
    },
    subtitle: {
      EN: `The Global Recycled Standard (GRS) is an international, voluntary and comprehensive standard that establishes the requirements for recycled material content, chain of custody, social and environmental requirements and chemical restrictions for the certification of products. The aim of the GRS standard is to increase the use of recycled materials in products as well as reduce/eliminate the risks caused by their production.`,
      IT: `Il Global Recycled Standard (GRS) è uno standard internazionale, volontario e completo che stabilisce i requisiti per la certifcazione da parte di terzi del contenuto riciclato, della catena di custodia, delle pratiche sociali e ambientali e delle restrizioni chimiche. L’obiettivo dello standard GRS è quello di aumentare l’uso di materiali riciclati nei prodotti nonchè ridurre/eliminare i rischi causati dalla loro produzione.`,
      FR: `Le Global Recycled Standard (GRS) est une norme internationale, volontaire et complète qui établit les critères de certification par des tiers du contenu recyclé, de la chaîne de contrôle, des pratiques sociales et environnementales et des restrictions au niveau de la composition chimique. L'objectif de la norme GRS est d'accroître l'utilisation de matériaux recyclés dans les produits et de minimiser/éliminer les risques liés à leur production.`
    },
    coverImage: "LOGO_GRS"
  },
  {
    title: {
      EN: "ISO 14001 CERTIFICATION",
      IT: "CERTIFICAZIONE ISO 14001",
      FR: "CERTIFICATION ISO 14001"
    },
    subtitle: {
      EN: `It defines the requirements of a company's Environmental Management System.`,
      IT: `Fissa i requisiti di un sistema di gestione ambientale di un'organizzazione aziendale.`,
      FR: `Elle définit les exigences d'un système de gestion de l'environnement d'une entreprise.`
    },
    coverImage: "LOGO_FSC"
  },
  {
    title: {
      EN: "ISO 45001 CERTIFICATION",
      IT: "CERTIFICAZIONE ISO 45001",
      FR: "CERTIFICATION ISO 45001"
    },
    subtitle: {
      EN: `Compliance with the international standard ISO 45001 (Occupational Health and Safety Assessment Specification) ensures conformity with the requirements for Occupational Health and Safety Management Systems and enables an organisation to better assess risks and improve its performance by carefully considering its context and stakeholders.`,
      IT: `La conformità allo standard internazionale ISO 45001 (Occupational Health and Safety Assessment Specifcation) assicura l'ottemperanza ai requisiti previsti per i Sistemi di Gestione della Salute e 
Sicurezza sul Lavoro e consente a un'organizzazione di valutare meglio i rischi e migliorare le proprie prestazioni, considerando con attenzione il proprio contesto e i propri interlocutori.`,
      FR: `La conformité à la norme internationale ISO 45001 (Spécification pour l'évaluation de la santé et de la sécurité au travail) garantit le respect des exigences prévues pour les systèmes de gestion de la santé et de la sécurité au travail. Elle permet à une entreprise de mieux évaluer les risques et d'améliorer ses performances en tenant soigneusement compte de son propre contexte et de ses propres interlocuteurs.`
    },
    coverImage: "LOGO_FSC"
  }
];
