<template>
  <div class="kvz-sustainability">
    <img
      :src="helper.getImage('SUST_NEW')"
      alt="sustainability"
      class="coverImage"
    />
    <div class="title">
      {{ title[selectedLanguage] }}
    </div>
    <div class="timeline-container container">
      <div class="vertical-line d-md-block" />
      <div
        v-for="(item, index) in sustainability"
        :key="index"
        :class="['item row', { revert: index % 2 === 0 }]"
      >
        <div
          :class="[
            'col-12 col-md-4 order-2 order-md-1 item--image',
            { left: index % 2 === 0 }
          ]"
        >
          <img v-if="item.image" :src="helper.getImage(item.image)" alt="" />
        </div>
        <div class="col-12 col-md-2 order-1 order-md-2 item--date">
          <div>
            {{ item.year }}
          </div>
        </div>
        <div
          :class="[
            'col-12 col-md-4 order-3 item--content',
            { left: index % 2 !== 0 }
          ]"
        >
          <div v-if="item.title" class="item--content__title">
            {{ item.title[selectedLanguage] }}
          </div>
          <div
            v-if="item.text"
            class="item--content__text"
            v-html="item.text[selectedLanguage]"
          />
          <div v-if="item.extra" class="item--content__accordion">
            <div :id="`accordion_${item.image}`" class="accordion">
              <div class="accordion-item">
                <h2 :id="`heading_${item.image}`" class="accordion-header">
                  <button
                    v-if="!accordion[item.image]"
                    :class="[
                      'accordion-button collapsed custom-button',
                      {
                        blackMode:
                          $store.state.values.activeTheme === 'dark-theme'
                      }
                    ]"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapse_${item.image}`"
                    aria-expanded="false"
                    :aria-controls="`collapse_${item.image}`"
                    @click="accordion[item.image] = !accordion[item.image]"
                  >
                    {{ labelAccordionMore[selectedLanguage] }}
                  </button>
                </h2>
                <div
                  :id="`collapse_${item.image}`"
                  class="accordion-collapse collapse"
                  :aria-labelledby="`heading_${item.image}`"
                  :data-bs-parent="`#accordion_${item.image}`"
                >
                  <button
                    v-if="accordion[item.image]"
                    class="custom-button"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapse_${item.image}`"
                    aria-expanded="false"
                    :aria-controls="`collapse_${item.image}`"
                    @click="accordion[item.image] = !accordion[item.image]"
                  >
                    {{ labelAccordionLess[selectedLanguage] }}
                  </button>
                  <div class="accordion-body">
                    <div v-if="item.extra.image">
                      <img :src="helper.getImage(item.extra.image)" alt="" />
                      <div class="accordion-body-text">
                        {{ item.extra.text[selectedLanguage] }}
                      </div>
                      <!-- <p v-if="item.extra.sub">
                        {{ item.extra.sub[selectedLanguage] }}
                      </p> -->
                    </div>
                    <div v-if="item.extra.graph">
                      <kvz-chart-sustainability />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <router-link
            v-if="item.linkTo"
            :to="{ name: item.linkTo }"
            class="item--content__link"
          >
            {{ labelLink[selectedLanguage] }}
          </router-link>
          <div v-if="item.source" class="item--content__source">
            {{ item.source[selectedLanguage] }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="more-to-come">
      <div>More to come</div>
    </div> -->
  </div>
</template>
<script>
import { sustainability, title } from "@/crm/sustainability";
import * as helper from "@/helper/helper";
export default {
  data() {
    return {
      helper: helper,
      title: title,
      sustainability: sustainability,
      labelLink: {
        IT: "Scopri come",
        EN: "Learn more",
        FR: "Découvrez comment"
      },
      labelAccordionMore: {
        IT: "scopri di più",
        EN: "learn more",
        FR: "Découvrez comment"
      },
      labelAccordionLess: {
        IT: "scopri di meno",
        EN: "learn less",
        FR: "Découvrez comment"
      },
      illustration: {
        IT: "Illustrazioni di",
        EN: "Illustrations by"
      },
      accordion: sustainability.reduce((acc, curr) => {
        return { ...acc, [curr.image]: false };
      }, {})
    };
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.values.language;
    }
  },

  methods: {}
};
</script>
<style lang="scss" scoped>
@import "@/scss/function";
@import "@/scss/color";
.title {
  margin: 3.4375rem auto;
  color: var(--primary-color);
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 3.25rem;
  letter-spacing: 1.6px;
}
.kvz-sustainability {
  padding: toRem(32) toRem(40);
  .coverImage {
    width: 100%;
    height: 40vh;
    object-fit: cover;
    border-radius: toRem(40);
    filter: saturate(0.7);
  }
  .timeline-container {
    padding-top: toRem(108);

    position: relative;
    z-index: 1;
    .item {
      display: flex;

      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: toRem(120);
      &:last-child {
        margin-bottom: 0;
      }
      &.revert {
        flex-direction: row-reverse;
      }
      &--content {
        text-align: right;
        &.left {
          text-align: left;
        }
        @media screen and (max-width: 767px) {
          text-align: center !important;
        }
        background: var(--background-color-primary);
        &__title {
          font-style: normal;
          font-weight: 700;
          font-size: toRem(30);
          line-height: toRem(35);
          letter-spacing: -0.02em;
          color: var(--accent-color);
          margin-bottom: 1rem;
        }
        &__text {
          font-style: normal;
          font-weight: 500;
          font-size: toRem(18);
          line-height: toRem(26);
          color: var(--primary-color);
        }
        &__source {
          color: var(--secondary-color);
          font-size: toRem(13);
          line-height: toRem(26);
          font-weight: 500;
        }
      }
      &--date {
        display: flex;
        height: toRem(150);

        align-items: center;
        justify-content: center;
        background: var(--background-color-primary);

        div {
          text-align: center;
          color: $white;
          border-radius: 8px;
          background: $green;
          width: fit-content;
          padding: 0 10px;
          height: toRem(30);
          line-height: toRem(29);
        }
      }
      &--image {
        text-align: right;

        &.left {
          text-align: left;
        }
        @media screen and (max-width: 767px) {
          text-align: center !important;
          margin-bottom: 2rem;
        }
        img {
          width: toRem(350);
          height: toRem(350);
          border-radius: toRem(40);
          overflow: hidden;

          @media screen and (max-width: 991px) {
            width: toRem(250);
            height: toRem(250);
          }
        }
      }
    }
    .vertical-line {
      height: 100%;
      position: absolute;
      border-left: 1px dashed var(--primary-color);
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: -1;
    }
  }
}

.accordion {
  //margin-top: 1rem;
  * {
    border: none;
    padding: 0;
    background: rgba(0, 0, 0, 0);
    color: inherit;
    box-shadow: none;
    &:focus,
    &:active {
      background: rgba(0, 0, 0, 0);
      color: inherit;
      box-shadow: none;
    }
  }
  position: relative;
  //padding-bottom: 1rem;
  border-bottom: 1px solid $green;
  &::after {
    content: "";
    position: absolute;
    background: $green;
    height: 4px;
    width: 4px;
    border-radius: 2px;
    bottom: -2.5px;
    right: 0;
  }
  .accordion-body {
    font-style: normal;
    font-weight: 500;
    font-size: toRem(14);
    line-height: toRem(18);

    color: var(--secondary-color);
    margin-bottom: 1rem;
    &-text {
      margin-top: toRem(18);
    }
    img {
      margin-top: 1rem;
      width: toRem(100);
      height: auto;
      border-radius: toRem(6);
    }
  }
  .accordion-button {
    &.blackMode::after {
      filter: invert(1) !important;
    }
  }
  .custom-button {
    padding: 1rem 0;
    font-style: normal;
    font-weight: 500;
    font-size: toRem(18);
    line-height: toRem(18);
    color: var(--accent-color);
  }
}
.illustration {
  color: var(--primary-color);
}
</style>
