<template>
  <div
    class="container-fluid kvz-container d-flex flex-wrap justify-content-between"
  >
    <div class="title">{{ certificationTitle[selectedLanguage] }}</div>
    <div class="articles">
      <kvz-certification
        v-for="(article, index) in certificationList"
        :key="index"
        :article="article"
      />
    </div>
  </div>
</template>

<script>
import { certificationTitle, certificationList } from "@/crm/certification";
export default {
  data() {
    return {
      certificationList: certificationList,
      certificationTitle: certificationTitle
    };
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.values.language;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/_function.scss";

// Contenitore principale
.kvz-container {
  padding-top: toRem(100);
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 5rem;
  display: flex;
  flex-wrap: wrap;

  .title {
    flex: 0 0 100%;
    margin: 0 2rem 2rem 0;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.75rem;
    line-height: 2rem;
    color: var(--primary-color);
    text-align: left;

    @media (min-width: 991px) {
      flex: 0 0 30%;
    }
    @media (min-width: 1207px) {
      flex: 0 0 20%;
    }
  }
  .articles {
    width: 100%;
    @media (min-width: 991px) {
      flex: 0 0 65%;
    }
    @media (min-width: 1207px) {
      flex: 0 0 75%;
    }
  }
}
</style>
