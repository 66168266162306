export const homepage = [
  {
    id: 0,
    img: "IMG_6670",
    EN: "Lasered insoles made of NPU Green / KPB",
    IT: "Solette laserate realizzate in NPU Green / KPB",
    FR: "Semelles laserées en NPU Green / KPB"
  },
  {
    id: 1,
    img: "IMG_6677-2",
    EN: "Shoe with NPU Green insole without steel shank",
    IT: "Scarpa con soletta in NPU Green senza lamina in acciaio",
    FR: "Chaussure avec semelle en NPU Green sans cheville en acier"
  },
  {
    id: 2,
    img: "IMG_6697",
    EN: "Insoles made of NPU Green and BRL",
    IT: "Solette realizzate con NPU Green e BRL",
    FR: "Semelles en NPU Green et BRL"
  },
  {
    id: 3,
    img: "IMG_6652",
    EN: "Insoles made of BLR / LRC / LRCS",
    IT: "Sottopiedi realizzati in BLR / LRC / LRCS",
    FR: "Semelles en BLR / LRC / LRCS"
  },
  {
    id: 4,
    img: "IMG_6698",
    EN: "Heel counters and reinforcements made of BIO",
    IT: "Contrafforti e rinforzi realizzati in BIO",
    FR: "Renforts en BIO"
  },
  {
    id: 5,
    video: "FSC_GRS",
    EN: "Certified raw material",
    IT: "Materia prima certificata",
    FR: "Matière première certifiée"
  },
  {
    id: 6,
    img: "DSC_3258",
    EN: "Insoles made of NPU Green and LRC",
    IT: "Solette realizzate in NPU Green e LRC",
    FR: "Semelles en NPU Green et LRC"
  },
  {
    id: 7,
    img: "DSC_3259",
    EN: "Insoles made of NPU Green",
    IT: "Soletta realizzata in NPU Green",
    FR: "Semelle en NPU Green"
  },
  {
    id: 8,
    img: "DSC_3260",
    EN: "Insole made from NPU Green and LRC",
    IT: "Soletta realizzata in NPU Green e LRC",
    FR: "Semelle en NPU Green et LRC"
  },
  {
    id: 9,
    img: "IMG_6687",
    EN: "Insole made from NPU Green and BRL",
    IT: "Soletta realizzata con NPU Green e BRL",
    FR: "Semelle en NPU Green et BRL"
  },
  {
    id: 10,
    img: "IMG_6717",
    EN: "Reinforcement fabrics made of GRS-certified cotton",
    IT: "Tele da rinforzo in contone certificato GRS",
    FR: "Tissu de renfort en coton certifié GRS"
  },
  {
    id: 11,
    img: "IMG_6724",
    EN: "PK thermoadhesive film",
    IT: "Pellicola termoadesiva PK",
    FR: "Film thermoadhésif PK"
  },
  {
    id: 12,
    img: "DSC_3261a",
    EN: "Insole made of NPU Green, BRL and SQN",
    IT: "Soletta realizzata in NPU Green, BRL e SQN",
    FR: "Semelle en NPU Green, BRL et SQN"
  },
  {
    id: 13,
    img: "DSC_3262a",
    EN: "Insole made of NPU Green and HEVEA",
    IT: "Soletta realizzata in NPU Green e HEVEA",
    FR: "Semelle en NPU Green et HEVEA"
  },
  {
    id: 14,
    img: "DSC_3279",
    EN: "Counters and toe-puffs made of BIO",
    IT: "Contrafforti e puntali realizzati in BIO",
    FR: "Renforts et renforts en BIO"
  }
];
