<template>
  <div class="row">
    <div class="col-sm-12 col-md-2">
      <img
        :src="helper.getImage(article.coverImage)"
        alt=""
        class="coverImage"
      />
    </div>
    <div class="col-sm-12 col-md-10 article">
      <div>
        <h4 class="article--title">{{ article.title[selectedLanguage] }}</h4>
        <div
          class="article--subtitle"
          v-html="article.subtitle[selectedLanguage]"
        />
      </div>
    </div>
  </div>
</template>
<script>
import * as helper from "@/helper/helper";
export default {
  props: {
    article: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
      helper: helper,
      linklabel: {
        EN: "learn more",
        IT: "scopri di più"
      }
    };
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.values.language;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/function";
.row {
  border-top: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  padding: toRem(24) 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > * {
    padding: 0;
  }
}
.coverImage {
  height: toRem(150);
  width: -webkit-fill-available;
  padding: 0 toRem(23) 0 0;
  object-fit: contain;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}
.article {
  //padding-left: toRem(23);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  &--title {
    font-style: normal;
    font-weight: 700;
    font-size: toRem(20);
    line-height: toRem(32);
    color: var(--primary-color);
    margin: 0;
  }
  &--subtitle {
    font-style: normal;
    font-weight: 300;
    font-size: toRem(16);
    line-height: 1.5;
    color: var(--primary-color);
    margin-top: 5px;
  }
}
</style>
