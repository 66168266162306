<template>
  <div>
    <div id="videoWrapperAboutUs" class="video-wrapper">
      <video
        :src="helper.getVideo('VIDEO_IN_APERTURA')"
        autoplay
        loop
        playsinline
        muted
      />
      <div class="text-overlay">
        <h2>
          {{ videoOverlay[selectedLanguage] }}
        </h2>
      </div>
    </div>
    <kvz-paragraph header-size-class="fs-32" :has-separator="true">
      <template #title>
        {{ paragraphAboutUs.title[selectedLanguage] }}
      </template>
      <template #body>
        {{ paragraphAboutUs.body[selectedLanguage] }}
      </template>
    </kvz-paragraph>

    <div
      id="wrapper-carousel"
      class="container-fluid custom-container position-relative"
    >
      <div class="timeline-wrapper">
        <h5 id="carousel-title" class="timeline-title">
          {{ history[selectedLanguage] }}
        </h5>
        <span class="timeline" />
        <span class="timeline-arrow" />
      </div>
      <div class="drag">
        {{
          selectedLanguage === "EN"
            ? "Drag to learn more"
            : selectedLanguage === "IT"
            ? "Scorri per scoprire"
            : "Faites défiler la page pour découvrir notre histoire"
        }}
      </div>
    </div>

    <div
      id="swiper-container"
      ref="scrollable"
      v-dragscroll="true"
      class="swiper-container"
      :style="`padding-right: ${offsetCarousel}px; padding-left: ${offsetCarousel}px`"
      @mousedown="disableAutoScroll"
      @touchstart="disableAutoScroll"
      @touchend="enableAutoScroll"
      @mouseup="enableAutoScroll"
    >
      <div id="tranlate-container" class="d-flex align-items-center">
        <section
          v-for="(slide, index) in carousel"
          :key="slide.id"
          class="swiper-slide"
        >
          <div
            v-for="(image, id) in slide.images"
            :key="id"
            :class="[
              'image-wrapper position-relative',
              { 'full-width': slide.images.length === 1 },
              { overlay: slide.images.length !== 1 }
            ]"
          >
            <div class="description description--top">
              <span class="year">{{ slide.year }}</span>
            </div>
            <!-- Math.floor(Math.random() * (10 - 7 + 1) + 7) / 10 -->

            <img
              :src="helper.getImage(image.img)"
              loading="lazy"
              alt=""
              :style="`zoom:${(index + id) % 2 === 0 ? 1 : 0.8}`"
            />
            <div class="description description--bottom">
              <span v-html="image[selectedLanguage]" />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import {
  videoOverlay,
  paragraphAboutUs,
  history,
  carousel
} from "@/crm/aboutUs";
import * as helper from "@/helper/helper";
import { dragscroll } from "vue-dragscroll";

export default {
  directives: {
    dragscroll
  },
  data() {
    return {
      offsetCarousel: 0,
      helper: helper,
      videoOverlay: videoOverlay,
      paragraphAboutUs: paragraphAboutUs,
      history: history,
      carousel: carousel,
      enableScroll: false,
      scrollInterval: null,
      horizon: null,
      swiper: null
    };
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.values.language;
    }
  },
  mounted() {
    this.scrollListener();
    this.observeCarousel();
    this.horizon = document.getElementById("tranlate-container");
    this.swiper = document.getElementById("swiper-container");
    const inViewport = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.enableAutoScroll();
        } else {
          this.disableAutoScroll();
        }
      });
    };

    const Obs = new IntersectionObserver(inViewport);

    Obs.observe(this.horizon);
  },
  methods: {
    update() {
      if (!this.enableScroll) {
        return;
      }
      this.scrollInterval = setInterval(() => {
        this.$refs.scrollable.scrollLeft += 1;
      }, 20);
    },
    disableAutoScroll() {
      clearInterval(this.scrollInterval);
      this.enableScroll = false;
    },
    enableAutoScroll() {
      this.enableScroll = true;
      this.update();
    },
    observeCarousel() {
      const carouselWrapper = document.getElementById("wrapper-carousel");
      const resizeObserver = new ResizeObserver(() => {
        const carouselHeader = document.getElementById("carousel-title");
        if (window.innerWidth < 700) {
          this.offsetCarousel = 32;
        } else {
          this.offsetCarousel = carouselHeader?.offsetWidth * 1.5;
        }
      });
      if (carouselWrapper) {
        resizeObserver.observe(carouselWrapper);
      }
    },
    scrollListener() {
      const videoWrapper = document.getElementById("videoWrapperAboutUs");

      document.addEventListener("scroll", (event) => {
        const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0;

        var scale =
          (document.body.offsetHeight - scrollTop) / document.body.offsetHeight;
        if (scale < 0.5) {
          return;
        }
        videoWrapper.style.setProperty("--headerScale", scale);
      });
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/scss/function";
@import "@/scss/color";
:root {
  --headerScale: 1;
}
.video-wrapper {
  position: relative;
  transform: scale(var(--headerScale));
  video {
    width: 100%;
    max-height: calc(100vh - 5.4375rem);
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: toRem(90);
  }
  .text-overlay {
    color: $white;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 3px;
  }
}
.timeline-wrapper {
  margin-top: toRem(90);
  display: flex;
  align-items: center;
  position: relative;
  .timeline-title {
    text-transform: uppercase;
    color: var(--primary-color);
    font-style: normal;
    font-weight: 700;
    font-size: toRem(24);
    line-height: toRem(24);
    flex-grow: 1;
    margin-right: 1rem;
  }
  .timeline {
    height: 2px;
    background: var(--blue-color);
    flex-grow: 999;
    &-arrow {
      position: absolute;
      right: 0;
      display: block;
      width: toRem(10);
      height: toRem(10);
      border-top: 2px solid var(--blue-color);
      border-left: 2px solid var(--blue-color);
      transform: rotate(135deg);
    }
  }
}

.swiper-container {
  padding-top: toRem(100);
  padding-bottom: toRem(100);
  overflow: auto;
  // overflow-y: hidden;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  display: flex;
  height: 110%;
  .swiper-slide {
    cursor: grab;
    display: flex;
    margin-right: 6rem;
    align-items: center;
  }
  .image-wrapper {
    display: flex;

    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    &.overlay {
      margin-left: 2rem;
    }
    .description {
      margin: 0.9rem 0;
      color: var(--primary-color);
      font-style: normal;
      font-weight: 500;
      font-size: toRem(20);
      line-height: toRem(24);
      position: absolute;
      &--top {
        bottom: 100%;
      }
      &--bottom {
        top: 100%;
      }
      .year {
        text-decoration: underline;
        text-decoration-color: var(--blue-color);
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
        font-weight: 600;
        margin-bottom: 8px;
        line-height: toRem(32);
      }
    }
  }
}

img {
  max-height: 450px;
  max-width: 400px;
}
@media screen and (min-width: 798px) {
  .text-overlay h2 {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0.15em solid white; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  }
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: rgba(0, 0, 0, 0);
  }
  50% {
    border-color: white;
  }
}
.drag {
  color: var(--blue-color);
  animation: move 1s linear infinite;
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translate(-50%, 0);
}

@keyframes move {
  0%,
  100% {
    top: 40px;
  }
  50% {
    top: 45px;
  }
}
</style>
