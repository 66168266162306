export const title = {
  EN: "We believe that sustainability is a journey, and our mission is to become the best version of ourselves.\nTherefore, Kiefer von Zoe® and its commitment to sustainability have been coexisting since 2007, when we launched our “The Earth at the Heart Project”",
  IT: `Crediamo che la sostenibilità sia un viaggio e la nostra missione è diventare 
la versione migliore di noi stessi.\nPer questo, Kiefer von Zoe® e il suo impegno 
sostenibile coesistono fin dal 2007, quando abbiamo lanciato il nostro “The Earth 
at the Heart Project”`,
  FR: "Le développement durable est pour nous comme un parcours enrichissant où chaque étape nous pousse à nous améliorer.\nC'est ainsi que Kiefer von Zoe® a choisi de concrétiser son engagement en lançant en 2007 son projet “The Earth at the Heart Project”."
};
export const sustainability = [
  {
    image: "1_FSC",
    year: "2007",
    title: {
      IT: "MATERIA PRIMA CERTIFICATA",
      EN: "CERTIFIED RAW MATERIAL",
      FR: "MATIÈRE PREMIÈRE CERTIFIÉE"
    },
    text: {
      IT: "Da sempre, per la produzione dei nostri fibrati utilizziamo esclusivamente cellulosa proveniente da foreste gestite responsabilmente.",
      EN: "We have always been using only cellulose from responsibly managed forests for the production of our fibreboards.",
      FR: `Depuis toujours, nous utilisons uniquement
de la cellulose provenant de forêts gérées
de manière responsable pour produire nos
matériaux fibrés.`
    }
  },
  {
    image: "2_BIODEGRADABILITA_E_IMPIANTI",
    year: "2007",
    title: {
      IT: "SOSTENIBILITÀ DEGLI IMPIANTI ",
      EN: "SUSTAINABILITY OF PLANTS ",
      FR: "DURABILITÉ DES INSTALLATIONS"
    },
    text: {
      IT: `Certificazione e mappatura della filiera
rappresentano due pilastri fondamentali
della nostra strategia e del nostro impegno
sostenibile.
\nNon solo i materiali Kiefer von Zoe®
soddisfano rigorosi criteri ambientali, ma ci
impegniamo affinché anche la nostra
azienda, gli stabilimenti produttivi e i
fornitori a cui scegliamo di appoggiarci
soddisfano gli stessi standard.`,
      FR: `La certification et la structuration maîtrisée
de notre filière sont deux piliers
fondamentaux de notre stratégie et de
notre engagement en matière de
développement durable.
\nNon seulement nos matériaux
Kiefer von Zoe® répondent à des critères
environnementaux stricts, mais nous nous
engageons également à veiller à ce que
notre propre entreprise, nos installations
de production et les fournisseurs que nous
choisissons d&#39;utiliser respectent également
de telles normes.`,
      EN: "Sustainability is a core element of our corporate culture and philosophy. Not only do Kiefer von Zoe® materials meet strict environmental criteria, we also make sure that our production facilities and the suppliers we engage with share this very same commitment. It is also crucial that Kiefer von Zoe® materials have a low environmental impact as far as its disposal is concerned. In line with this objective, the R&D department has always been focused on the development of biodegradable fibreboards and reinforcement materials."
    }
    // extra: {
    //   text: {
    //     IT: "A proposito degli nostri stabilimenti produttivi, siamo fieri di poter affermare che l' 80% dei nostri è certificato con riferimento allo standard ISO 14001 (Sistemi di Gestione Ambientale), il 70% secondo lo standard ISO 9001 (Sistemi di Gestione della Qualità), il 40% in riferimento allo standard ISO 45001 (Sistemi di Gestione per la Salute e la Sicurezza dei Lavoratori - ex OHSAS 18001) e il 30% secondo lo standard ISO 50001 (Sistemi di Gestione per l' Energia).",
    //     EN: "As for our production facilities, we are proud to state that 80% are certified to ISO 14001 (Environmental Management Systems), 70% to ISO 9001 (Quality Management Systems), 40% to ISO 45001 (Occupational Health and Safety Management Systems - formerly OHSAS 18001) and 30% to ISO 50001 (Energy Management Systems)."
    //   },
    //   graph: "kvz-iso-graph"
    // }
  },
  {
    image: "3_ANALISI",
    year: "2017",
    title: {
      IT: "ANALISI SU LOTTI PRODOTTI ",
      EN: "ANALYSIS OF PRODUCED BATCHES",
      FR: "ANALYSE DES LOTS DE PRODUITS"
    },
    text: {
      IT: "Per attestare la naturalità dei nostri materiali svolgiamo sistematicamente analisi sui lotti prodotti presso laboratori terzi accreditati. Inoltre, abbiamo condotto (con successo) test di biodegrabilità sui nostri fibrati e materiali da rinforzo.",
      EN: "To certify that our products are environment-friendly, we systematically carry out analyses on the batches produced at third-party accredited laboratories. In addition, we have (successfully) conducted biodegradability tests on our fibreboards and reinforcement materials.",
      FR: `Pour attester que nos matériaux sont naturels, nous effectuons systématiquement des analyses sur les lots produits dans des laboratoires tiers accrédités. D'autre part, nous avons effectué et réussi des tests de biodégradabilité sur nos matériaux fibrés et sur nos matériaux de renfort.`
    }
  },
  {
    image: "4_CoC",
    year: "2020",
    title: {
      IT: "CERTIFICAZIONE DELLA CATENA DI CUSTODIA FSC",
      EN: "FSC CHAIN OF CUSTODY CERTIFICATION",
      FR: "CERTIFICATION DE LA CHAÎNE DE CONTRÔLE FSC"
    },
    text: {
      IT: "Kiefer von Zoe® ha ottenuto la certificazione per la catena di custodia FSC sia per la cellulosa che per il lattice naturale.",
      EN: "Kiefer von Zoe® has obtained the FSC Chain of Custody certification for both cellulose and natural latex.",
      FR: "Kiefer von Zoe® a obtenu la certification de chaîne de contrôle FSC pour la cellulose comme pour le latex naturel."
    }
    // extra: {
    //   text: {
    //     IT: "Cerca i nostri prodotti certificati FSC®",
    //     EN: "Find out our FSC® certified products"
    //   },
    //   image: "FSC_C159192"
    // }
  },
  {
    image: "5_CONSULTING",
    year: "2021",
    title: {
      IT: "KVZ CONSULTING",
      EN: "KVZ CONSULTING",
      FR: "KVZ CONSULTING"
    },
    text: {
      IT: `Dal 2021, con il lancio di una divisione consulenziale dedicata, Kiefer von Zoe® mette a disposizione dei Brand l'esperienza e le competenze del proprio Team R&D per lo sviluppo di materiali ad hoc, fino a prima inesistenti. 
Attraverso il programma Tailormade di KvZ Consulting, i Brand possono campionare materiali sostenibili unici, realizzati su misura per soddisfare ogni richiesta tecnica.<br/><a class='item--content__link' style=' font-size:16px; text-decoration: underline; text-decoration-color: var(--blue-color);text-decoration-thickness: 2px; text-underline-offset: 4px;' href="mailto:commerciale@kiefervonzoe.com">Scopri come</a>`,
      EN: `Since 2021, with the launch of a dedicated business division, Kiefer von Zoe® has been making the experience and expertise of its R&D team available to brands for the development of ad-hoc materials that did not exist before. 
Through the tailor-made programme of KvZ Consulting, brands can sample unique sustainable materials, specifically tailored to meet
every technical requirement.<br/><a class='item--content__link' style='  font-size:16px; text-decoration: underline; text-decoration-color: var(--blue-color);text-decoration-thickness: 2px; text-underline-offset: 4px;' href="mailto:commerciale@kiefervonzoe.com">Learn more.</a>`,
      FR: `En 2021, Kiefer von Zoe® a lancé une division conseil dédiée qui met l'expérience et l'expertise de son équipe R et D à la disposition des marques pour le développement de matériaux ad hoc jusqu'alors inexistants. 
Grâce au programme personnalisé de KvZ Consulting, les marques peuvent créer des échantillons de matériaux durables uniques, conçus sur mesure pour répondre à n'importe quelle exigence technique.<br/><a class='item--content__link' style='  text-decoration: underline; text-decoration-color: var(--blue-color);text-decoration-thickness: 2px; text-underline-offset: 4px; font-size:16px' href="mailto:commerciale@kiefervonzoe.com">Découvrez comment.</a<`
    }
  },
  {
    image: "6_BORRACCIA",
    year: 2021,
    title: {
      IT: "BORRACCIA KVZ",
      EN: "KVZ BOTTLE",
      FR: "GOURDE KVZ"
    },
    text: {
      IT: "Dal 2021, in linea con l' obiettivo di ridurre costantemente la quantità di plastica presente all' interno della nostra organizzazione, abbiamo eliminato definitivamente le bottigliette usa e getta, dotando l'intero team Kiefer von Zoe® di borracce. Ogni volta che riempiamo una di queste borracce, evitiamo l'immissione di -0,08 Kg di CO2 EQ nell'atmosfera - la stessa quantità di CO2 emessa nella produzione di una bottiglietta di plastica usa e getta (*)",
      EN: "In line with our goal of constantly reducing the amount of plastic in our organization, since 2021 we have completely eliminated disposable bottles, equipping the entire Kiefer von Zoe® team with thermal bottles. Every time we fill one of these water bottles, we avoid the emission of -0.08 kg of CO2 EQ into the atmosphere - the same amount of CO2 emitted in the production of a disposable plastic bottle (*).",
      FR: "Conformément à notre objectif de réduire toujours plus la quantité de plastique dans notre organisation, nous avons en 2021 définitivement éliminé les bouteilles jetables, en équipant toute l'équipe de Kiefer von Zoe® de gourdes. Chaque fois que nous remplissons une de ces gourdes, nous évitons l'émission de -0,08 kg d'éq. CO2 dans l'atmosphère — la même quantité de CO2 émise lors de la production d'une bouteille en plastique jetable (*)."
    },

    source: {
      IT: "*Fonte: 24bottles",
      EN: "*Source: 24bottles",
      FR: "*Source: 24bottles"
    }
  },
  {
    image: "7_BEE_KIEFER",
    year: "2022-2023",
    title: {
      IT: "BEE KIEFER",
      EN: "BEE KIEFER",
      FR: "BEE KIEFER"
    },
    text: {
      IT: "Da oltre 15 anni ci impegniamo a innovare in modo sostenibile, a preservare la biodiversità e a creare un impatto sociale positivo, restituendo alla natura le sue ricchezze attraverso un approvvigionamento sostenibile delle materie prime. Nel 2022, questo impegno – animato dal senso di dovere che ci spinge ad agire per consegnare alle generazioni future un mondo migliore di quello che abbiamo ereditato – ha raggiunto una nuova frontiera con il lancio del progetto “BEE KIEFER”. La naturale evoluzione del nostro percorso coincide, infatti, con la decisione di iniziare a dedicarci alla protezione e preservazione di uno dei tesori più preziosi della natura: le api.",
      EN: "For over 15 years, we have been committed to sustainable innovation, preserving biodiversity and creating a positive social impact by giving back to nature its treasures through sustainable sourcing of raw materials. Driven by a sense of duty to deliver to future generations a better world than the one we inherited, this commitment has reached in 2022 a new frontier with the launch of our 'BEE KIEFER' project. The natural evolution of our path, in fact, coincides with the decision to start dedicating ourselves to the protection and preservation of one of nature's most precious treasures: bees.",
      FR: "Depuis plus de 15 ans, nous nous engageons en faveur de l'innovation durable, de la préservation de la biodiversité et de la création d'un impact social positif en restituant à la nature ses richesses grâce à un approvisionnement durable en matières premières. Accrue par le sentiment qu'il est de notre devoir d'agir pour transmettre aux générations futures un monde meilleur que celui dont nous avons hérité, notre détermination a pris une nouvelle dimension en 2022 grâce au lancement du projet « BEE KIEFER ». L'évolution naturelle de notre parcours coïncide en effet avec la décision de commencer à nous consacrer à la protection et à la préservation de l'un des trésors les plus précieux de la nature: les abeilles. Découvrez comment."
    },
    linkTo: "latest"
  },
  {
    image: "08_GRS",
    year: "2024",
    title: {
      IT: "CERTIFICAZIONE GRS",
      EN: "GRS CERTIFICATION",
      FR: "CERTIFICATION GRS"
    },
    text: {
      IT: "Kiefer von Zoe® ha ottenuto la certifcazione GRS (Global Recycled Strandard).<br/>Solo i prodotti coperti da un transaction certificate in corso di validità sono certifcati GRS.",
      EN: "Kiefer von Zoe® has achieved the GRS (Global Recycled Strandard) certification.<br/>Only products covered by a valid transaction certificate are GRS certified.",
      FR: "Kiefer von Zoe® a obtenu la certification GRS (Global Recycled Standard).<br/>Seuls les produits pourvus d'un Certificat de transaction en cours de validité sont certifiés GRS"
    }
  },
  {
    year: "More to come"
  }
];
