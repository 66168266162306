export const socialList = {
  title: {
    EN: "Follow us",
    IT: "Follow us",
    FR: "Follow us"
  },
  items: [
    {
      label: "Linkedin",
      link: "https://www.linkedin.com/company/kiefer-von-zoe"
    },
    {
      label: "Instagram",
      link: "https://www.instagram.com/kiefervonzoe_official/"
    },
    // {
    //   label: "Facebook",
    //   link: ""
    // },
    {
      label: "YouTube",
      link: "https://www.youtube.com/channel/UCx-tXcgO8g84s4IGWC7VQsQ/featured"
    }
  ]
};
