export const articleList = [
  {
    parseTitle: "CERTIFICATION_GRS",
    title: {
      EN: "CERTIFICATION GRS",
      IT: "CERTIFICAZIONE GRS",
      FR: "CERTIFICATION GRS"
    },
    subtitle: {
      EN: `Kiefer von Zoe® has achieved the GRS (Global Recycled Standard) certification by Textile Exchange.`,
      IT: "Kiefer von Zoe® ha ottenuto la certificazione GRS (Global Recycled Standard) di Textile Exchange.",
      FR: "Kiefer von Zoe® a obtenu la certification GRS (Global Recycled Standard) de l'organisation Textile Exchange."
    },
    pupblicationDate: {
      EN: "21/08/2024",
      IT: "21/08/2024",
      FR: "21/08/2024"
    },
    coverImage: "TESSUTO"
  },
  {
    parseTitle: "KVZ_ESSENTIAL",
    title: {
      EN: "KIEFER VON ZOE Essentials",
      IT: "KIEFER VON ZOE Essentials",
      FR: "KIEFER VON ZOE Essentials"
    },
    subtitle: {
      EN: "A look at the major innovations brought by Kiefer von Zoe® in the fashion industry",
      IT: "Uno sguardo alle maggiori innovazioni portate da Kiefer von Zoe® nell'industria fashion",
      FR: `Les innovations visionnaires
Kiefer von Zoe® qui transforment
l'industrie de la mode`
    },
    pupblicationDate: {
      EN: "09/05/2023",
      IT: "09/05/2023",
      FR: "09/05/2023"
    },
    coverImage: "LINKEDIN_NEWSLETTER"
  },
  {
    parseTitle: "BEE_KIEFER",
    title: {
      EN: "BEE KIEFER",
      IT: "BEE KIEFER",
      FR: "BEE KIEFER"
    },
    subtitle: {
      EN: "Find out how Kiefer von Zoe® is committed to protecting bees and preserving biodiversity",
      IT: "Scopri come Kiefer von Zoe® si sta impegnando per proteggere le api e preservare la biodiversità",
      FR: `Découvrez comment Kiefer von Zoe®
œuvre pour la protection et la préservation
de la biodiversité.`
    },
    pupblicationDate: {
      EN: "20/12/2022",
      IT: "20/12/2022",
      FR: "20/12/2022"
    },
    coverImage: "HEADER"
  }
];
export const BEE_KIEFER = {
  title: {
    EN: "BEE KIEFER",
    IT: "BEE KIEFER",
    FR: "BEE KIEFER"
  },
  coverImage: "HEADER",
  content: [
    {
      paragraphs: [
        {
          IT: "Da oltre 15 anni ci impegniamo a innovare in modo sostenibile, a preservare la biodiversità e a creare un impatto sociale positivo, restituendo alla natura le sue ricchezze attraverso un approvvigionamento sostenibile delle materie prime.",
          EN: "For over 15 years, we’ve been committed to sustainable innovation, the preservation of biodiversity and the generation of positive social impact, restoring the richness of nature by using sustainably sourced raw materials.",
          FR: `Depuis plus de 15 ans, nous nous
engageons en faveur de l'innovation
durable, de la préservation de la
biodiversité et de la création d'un impact
social positif en restituant à la nature ses
richesses grâce à un approvisionnement
durable en matières premières.`
        },
        {
          IT: "Nel 2022, questo impegno – animato dal senso di dovere che ci spinge ad agire per consegnare alle generazioni future un mondo migliore di quello che abbiamo ereditato – ha raggiunto una nuova frontiera con il lancio del progetto “BEE KIEFER”.",
          EN: "In 2022, this commitment, invigorated by the sense of duty that pushes us to act so that future generations will inherit a better world, has reached a new frontier with the launch of the BEE KIEFER project.",
          FR: `Accrue par le sentiment qu'il est de notre
devoir d'agir pour transmettre aux
générations futures un monde meilleur que
celui dont nous avons hérité, notre
détermination a pris une nouvelle
dimension en 2022 grâce au lancement du
projet “BEE KIEFER”.`
        },
        {
          IT: "La naturale evoluzione del nostro percorso coincide, infatti, con la decisione di iniziare a dedicarci alla protezione e preservazione di uno dei tesori più preziosi della natura: le api.",
          EN: "And indeed, the natural evolution of our path coincides with the decision to dedicate our time and energy to the protection and preservation of one of nature's most precious treasures: bees.",
          FR: `L'évolution naturelle de notre parcours
coïncide en effet avec la décision de
commencer à nous consacrer à la
protection et à la préservation de l'un des trésors les plus précieux de la nature: les
abeilles.`
        }
      ]
    },
    {
      title: {
        EN: "WHY BEES?",
        IT: "PERCHÉ LE API?",
        FR: "POURQUOI LES ABEILLES?"
      },
      quote: {
        IT: "“Se le api scomparissero dalla Terra, per l’uomo non resterebbero che quattro anni di vita”",
        EN: "“If the bee disappeared off the face of the Earth, man would only have four years left to live.”",
        FR: `“Si les abeilles disparaissaient de la
Terre, il ne resterait que quatre ans à
l'humanité”`
      },
      paragraphs: [
        {
          IT: "Secondo la FAO, infatti, l’87% delle coltivazioni che nutrono l’uomo si riproduce grazie all’impollinazione, proprio come 3 quarti delle colture più importanti al mondo. Se il numero di api continuerà a diminuire, non sarà solo la nostra sicurezza alimentare a vacillare, ma anche il futuro del nostro ecosistema.",
          EN: "According to the Food and Agriculture Organization of the United Nations (FAO), 87% of crops grown for human consumption reproduce thanks to pollination; the same is true of three-quarters of the most important crops in the world. If the number of bees continues to decline, it won’t only be our food security to waver, but also the future of our ecosystem.",
          FR: `En effet, selon la FAO, 87% des cultures
qui nourrissent l'humanité se reproduisent
par pollinisation, tout comme les trois
quarts des cultures mondiales les plus
importantes. Si le nombre d'abeilles
continue à diminuer, ce n'est pas
seulement notre sécurité alimentaire qui
vacillera, mais également le futur de notre
écosystème.`
        },
        {
          IT: "Gli insetti impollinatori – e le api in particolare – sono in grave pericolo: si stima che negli ultimi 10 anni siano scomparsi 10 milioni di alveari, 200mila dei quali solo in Italia. (1)",
          EN: "Pollinators (bees in particular) are in grave danger. It is estimated that, in the last 10 years, 10 million beehives have disappeared, 200,000 in Italy alone. (1)",
          FR: `Les insectes pollinisateurs — et les
abeilles en particulier — sont en grave
danger: on estime que 10 millions de
ruches ont disparu au cours des
10 dernières années, dont 200,000 rien
qu'en Italie. (1)`
        },
        {
          IT: "Queste morie di massa di api sono state correlate a pesticidi (in particolare a un gruppo di sostanze chimiche chiamate neonicotinoidi), all’acaro parassita “Varroa destructor” e alla riduzione dell’habitat nativo aggravata dal diffondersi delle monocolture commerciali su larga scala. Ovviamente, anche i cambiamenti climatici hanno messo in difficoltà le specie endemiche di tutto il mondo, al punto che in Europa 1 ape su 10 è a rischio estinzione. (2), (3)",
          EN: "This massive die-off of bees has been correlated to pesticides (in particular one specific group of chemical substances called neonicotinoids), the Varroa destructor parasitic mite, and native habitat destruction that's only exacerbated by large-scale monoculture farming. And it goes without saying that climate change has put native species all over the world at risk, to the degree that in Europe, one out of ten bees is at risk of extinction. (2), (3)",
          FR: `Ces disparitions massives d'abeilles ont
été reconduites aux pesticides (en
particulier à un groupe de substances
chimiques appelées néonicotinoïdes),
mais aussi à l'acarien parasite “Varroa
destructor”, ainsi qu'à la réduction de
l'habitat indigène exacerbée par
l'extension des monocultures
commerciales à grande échelle. Le
changement climatique a évidemment
aussi mis en péril les espèces endémiques
du monde entier, à tel point que 1 abeille
sur 10 en Europe est menacée
d'extinction. (2), (3)`
        }
      ]
    },
    {
      title: {
        EN: "THE PROJECT",
        IT: "IL PROGETTO",
        FR: "LE PROJET"
      },

      paragraphs: [
        {
          IT: "BEE KIEFER sostiene l’allevamento di 150 arnie – circa 9 milioni di api (a) – grazie al lavoro di <a style='  text-decoration: underline; text-decoration-color: var(--blue-color);text-decoration-thickness: 2px; text-underline-offset: 4px;' href='http://mielefvg.it/index.html' target='blank'> un apicoltore dall’esperienza pluridecennale</a> e selezionato dal nostro Sustainability Department in uno dei territori più naturali e incontaminati d’Italia: il Friuli-Venezia Giulia.",
          EN: "BEE KIEFER provides support for 150 beehives—about 9 million bees (a)—thanks to the work of <a style='  text-decoration: underline; text-decoration-color: var(--blue-color);text-decoration-thickness: 2px; text-underline-offset: 4px;' href='http://mielefvg.it/index.html' target='blank'>a beekeeper with decades of experience</a> selected by our Sustainability Department, working in one of the most pristine natural areas of Italy: Friuli-Venezia Giulia.",
          FR: `BEE KIEFER soutient un élevage de
150 ruches, soit 9 millions d'abeilles
environ (a), grâce au travail d'un apiculteur
qui cumule des dizaines d'années
d'expérience et qui a été soigneusement
sélectionné par notre Département de
Durabilité dans l'une des zones les plus
naturelles et les plus préservées d'Italie: le
Frioul-Vénétie Julienne.`
        },
        {
          IT: "Le arnie – suddivise in 9 apiari (b) – sono distribuite lungo tutta la provincia di Udine – dall’alta pianura friulana alle Prealpi Giulie – e localizzate in aree dove sorgono prevalentemente colture e vegetazioni spontanee. Tutti i siti individuati dal “nostro” apicoltore sono, inoltre, distanti da strade ad alta densità di traffico e zone industriali.",
          EN: "Split among 9 apiaries (b), the beehives are spread along the entire province of Udine, from the Friulian highlands to the Julian Prealps, in areas where mainly crops and wild vegetation grow. Moreover, all the sites selected by ‘our’ beekeeper are far from roads, high-density traffic, and industrial zones.",
          FR: `Subdivisées en neuf ruchers (b), les
ruches sont réparties dans toute la
province d'Udine, de la haute plaine
frioulane aux Préalpes juliennes, dans des
zones où prédominent des cultures et une
végétation spontanée. Tous les sites identifiés par “notre” apiculteur sont par
ailleurs éloignés des routes à grande
circulation et des zones industrielles.`
        },
        {
          IT: "Al fine di garantire il benessere di questi straordinari insetti, tutti i materiali utilizzati per la realizzazione delle arnie sono naturali e l’allevamento segue metodi tradizionali: sia la raccolta dei melari – che avviene solamente quando il miele è totalmente opercolato nei faci (e quindi “maturo”) – che la smielatura sono operazioni completamente manuali, senza l’utilizzo soffiatori né di pompe aspiranti.",
          EN: "In order to guarantee the well-being of these extraordinary insects, only natural materials are used to make the beehives, and they're cared for and harvested using traditional methods. From the collection of honey and honeycombs (which generally happens when the honeycomb faces are covered in operculum, and thus mature) to the extraction of honey, everything is done manually, without the use of blowers or suction pumps.",
          FR: `Dans le but de garantir le bien-être de ces
insectes extraordinaires, tous les
matériaux utilisés pour la fabrication des
ruches sont naturels et l'élevage suit des
méthodes traditionnelles: la récolte du
miel des hausses des ruches — qui n'a
lieu que lorsque les nids d'abeilles sont
entièrement remplis de miel (et donc que
le miel est “mûr”) — ainsi que l'extraction
du miel sont des opérations entièrement
manuelles, sans souffleur ni pompe
d'aspiration.`
        },
        {
          IT: "BEE KIEFER è un progetto a più fasi, pensato per evolvere nel tempo raggiungendo risultati sempre più significativi:",
          EN: "BEE KIEFER is a multi-phase project, designed to evolve over time, achieving increasingly significant results over time.",
          FR: `BEE KIEFER est un projet en plusieurs
étapes, conçu pour évoluer au fil du
temps. C'est ainsi que les résultats
obtenus sont toujours plus significatifs:`
        }
      ]
    }
  ],
  sectionImages: [
    {
      img: "FASE_1",
      text: {
        title: {
          IT: "<b style='font-weight:700; text-transform:uppercase'>1. Piantumazione</b> (dal 2022)",
          EN: "<b style='font-weight:700; text-transform:uppercase'>1. Planting</b> (launched in 2022)",
          FR: "<b style='font-weight:700; text-transform:uppercase'>1. Plantation</b> (depuis 2022)"
        },
        description: {
          IT: "Dal 2022, presso i nostri headquarter, ci impegniamo nella piantumazione e nella cura di alcuni tra i fiori preferiti dalle api – come la lavanda e la “Cephalaria Transsylvanica” – con l’obbiettivo di offrire riparo e ristoro alle colonie che sorvolano i nostri prati.",
          EN: "Since 2022, at our headquarters, we've been committed to the planting and cultivation of flowers that bees love, such as lavender and Cephalaria Transsylvanica with the goal of offering habitat and food to the bee colonies near our fields.",
          FR: `Depuis 2022, nous nous occupons sur le
terrain de notre siège social de la
plantation et de l'entretien de certaines des
fleurs préférées des abeilles, telles que la
lavande et la “Cephalaria
Transsylvanica”, dans le but de créer des
lieux de refuge et de fournir des sources
de nourriture aux colonies qui survolent
nos prairies.`
        }
      }
    },

    {
      img: "FASE_2",
      text: {
        title: {
          IT: "<b style='font-weight:700; text-transform:uppercase'>2. Sensibilizzazione</b> (2022)",
          EN: "<b style='font-weight:700; text-transform:uppercase'>2. Raising awareness</b> (2022)",
          FR: "<b style='font-weight:700; text-transform:uppercase'>2. Sensibilisation</b> (2022)"
        },
        description: {
          IT: "Se la biodiversità è al centro del nostro impegno, l’ape è al centro della biodiversità. Con l’obbiettivo di sensibilizzare sull’importanza della salvaguardia di questi straordinari insetti, nel 2022 abbiamo brandizzato e distribuito oltre 200 chili del miele prodotto dall’apicoltore da noi selezionato.",
          EN: "Biodiversity is at the centre of our commitment, and bees are at the centre of biodiversity. With the goal of raising awareness about how important it is to protect these extraordinary insects, in 2022 we’ve branded and distributed over 200 kilos of honey produced by our selected beekeeper.",
          FR: `Si la biodiversité est au centre de nos
efforts, l'abeille, elle, est au centre de la
biodiversité. Dans une démarche de
sensibilisation sur l'importance de la
préservation de ces insectes
extraordinaires, nous avons marqué et
distribué en 2022 plus de 200 kilos de miel
produit par l'apiculteur que nous avons
sélectionné.`
        }
      }
    },
    {
      img: "FASE_3",
      text: {
        title: {
          IT: "<b style='font-weight:700; text-transform:uppercase'>3. Adozione</b> (dal 2023)",
          EN: "<b style='font-weight:700; text-transform:uppercase'>3. Adoption</b> (2023)",
          FR: "<b style='font-weight:700; text-transform:uppercase'>3. Adoption</b> (depuis 2023)"
        },
        description: {
          IT: "Dal 2023, il nostro impegno si traduce anche nell’adozione di un alveare. L’obiettivo di Kiefer von Zoe® è, infatti, quello di dare sostegno e supporto continuo all’eccezionale lavoro del “nostro” apicoltore, un vero e proprio custode della biodiversità.",
          EN: "Starting in 2023, we at Kiefer von Zoe® have expressed our commitment to bees by adopting a beehive. Our goal is to provide continued support to the exceptional work done by our beekeeper, a true custodian of biodiversity.",
          FR: `Depuis 2023, notre engagement se traduit
également par l'adoption d'une ruche.
L'objectif de Kiefer von Zoe® est en effet
de soutenir et de toujours faciliter le travail
exceptionnel de “notre” apiculteur,
véritable gardien de la biodiversité.`
        }
      }
    }
  ],
  notes: [
    {
      IT: "(a): Il termine “arnia” è sinonimo di “alveare” e indica la casa delle api. Si tratta cioè delle casette di legno colorate che ospitano una colonia di insetti ciascuna. Ogni colonia è composta da una sola regina, dalle operaie (femmine) e dai fuchi (maschi). Il numero di api per colonia",
      EN: "(a): These beehives are colourful wooden boxes that contain one bee colony. Each colony is composed of a single queen, worker bees (females) and drones (males). The population of a single colony can reach 50,000-60,000 bees in spring/summer, then decreases sharply in winter.",
      FR: `(a): Le terme “ruche” désigne l'habitat
des abeilles. Il s'agit de petites maisons colorées, en bois, qui abritent chacune une
colonie d'insectes. Chaque colonie est
composée d'une seule reine, des ouvrières
(femelles) et des faux bourdons (mâles).
La population d'une colonie peut compter
entre 50.000 et 60.000 individus au
printemps et en été, puis diminue
drastiquement en hiver.`
    },
    {
      IT: "(b): Con il termine “apiario”, invece, si intende l’insieme di più arnie localizzate nello stesso sito.",
      EN: "(b): Apiaries are defined as the larger grouping of multiple bee boxes (which protect the hives) in the same location.",
      FR: `(b): Le terme “rucher” désigne quant à
lui un ensemble de ruches situées sur un
même site.`
    }
  ],

  sources: [
    {
      IT: "(1): fonte – Confederazione italiana agricoltori",
      EN: "(1): Confederazione Italiana Agricoltori",
      FR: `(1): source — Confederazione italiana`
    },
    {
      IT: "(2): fonte – LifeGate",
      EN: "(2): LifeGate",
      FR: `(2): source – LifeGate`
    },
    {
      IT: "(3): fonte – National Geographic",
      EN: "(3): National Geographic",
      FR: `(3): source – National Geographic`
    }
  ]
};
export const KVZ_ESSENTIAL = {
  title: {
    EN: "KIEFER VON ZOE Essentials",
    IT: "KIEFER VON ZOE Essentials",
    FR: "KIEFER VON ZOE Essentials"
  },
  coverImage: "LINKEDIN_NEWSLETTER",
  content: [
    {
      paragraphs: [
        {
          IT: "Scopri la nuova LinkedIn newsletter “KIEFER VON ZOE Essentials” di Kiefer Von Zoe, una selezione sempre aggiornata delle principali innovazioni tecniche e sostenibili portate da Kiefer Von Zoe nei settori footwear e pelletteria.",
          EN: "Discover the new LinkedIn newsletter “KIEFER VON ZOE Essentials” by Kiefer Von Zoe, a constantly updated selection of the most important technical and sustainable innovations brought by Kiefer Von Zoe in the footwear and leather goods industries.",
          FR: `Découvrez la nouvelle lettre d'information
LinkedIn de Kiefer Von Zoe
“KIEFER VON ZOE Essentials”, une
sélection constamment mise à jour des
innovations techniques et durables les plus
importantes apportées par Kiefer Von Zoe
dans les secteurs de la chaussure et de la
maroquinerie.`
        },
        {
          IT: "Per non perdere nessun aggiornamento, iscriviti <a href='https://www.linkedin.com/newsletters/kiefer-von-zoe-essentials-7060287397275537408/?displayConfirmation=false' target='_blank'>qui</a>",
          EN: "Subscribe <a href='https://www.linkedin.com/newsletters/kiefer-von-zoe-essentials-7060287397275537408/?displayConfirmation=false' target='_blank'>here</a>, not to miss any update!",
          FR: `Pour ne manquer aucune
mise à jour, inscrivez-vous <a href='https://www.linkedin.com/newsletters/kiefer-von-zoe-essentials-7060287397275537408/?displayConfirmation=false' target='_blank'>ici</a>`
        }
      ]
    }
  ]
};
//TODO CHANGE THIS + add certification icon
export const CERTIFICATION_GRS = {
  title: {
    EN: "CERTIFICATION GRS",
    IT: "CERTIFICAZIONE GRS",
    FR: "CERTIFICATION GRS"
  },
  coverImage: "TESSUTO",
  contentImage: "CERTIFICATE_GRS",
  content: [
    {
      paragraphs: [
        {
          IT: "Kiefer von Zoe® ha ottenuto la certifcazione GRS di Textile Exchange.",
          FR: `Kiefer von Zoe® a obtenu la certification
GRS de Textile Exchange.`,
          EN: "Kiefer von Zoe® has achieved the GRS certification by Textile Exchange."
        },
        {
          IT: `Il Global Recycled Standard (GRS) è uno standard internazionale, volontario e completo che
stabilisce i requisiti del contenuto riciclato, della catena di custodia, delle pratiche sociali e
ambientali e delle restrizioni chimiche per la certifcazione dei prodotti.`,
          FR: `Le Global Recycled Standard (GRS) est
une norme internationale, volontaire et
complète qui établit des exigences en
matière de contenu recyclé, de chaîne de
contrôle, de pratiques sociales et
environnementales et de restrictions
chimiques pour la certification des
produits.`,
          EN: "The Global Recycled Standard (GRS) is an international, voluntary and comprehensive standard that establishes the requirements for recycled material content, chain of custody, social and environmental requirements and chemical restrictions for the certification of products."
        }
      ]
    }
  ]
};
