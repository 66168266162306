export const footerContacts = {
  title: {
    EN: "Contacts",
    IT: "Contatti",
    FR: "Contacts"
  },
  items: [
    {
      EN: "(+39) 0331 422868",
      IT: "(+39) 0331 422868",
      FR: "(+39) 0331 422868"
    },
    {
      EN: "support@kiefervonzoe.com",
      IT: "support@kiefervonzoe.com",
      FR: "support@kiefervonzoe.com",
      mailto: true
    },
    {
      EN: "Piazza Maggiolini, 34 - 20015 - Parabiago (MI) - Italy",
      IT: "Piazza Maggiolini, 34 - 20015 - Parabiago (MI) - Italia",
      FR: "Piazza Maggiolini, 34 - 20015 - Parabiago (MI) - Italie"
    }
  ]
};
export const footerCompanyData = {
  title: {
    EN: "kiefer von zoe® srl",
    IT: "kiefer von zoe® srl",
    FR: "kiefer von zoe® srl"
  },
  items: [
    {
      EN: "Kiefer von Zoe Srl - REA Milano n. MI-1835734 | Iscriz. Reg. Impr. Milano n. 05630890969",
      IT: "Kiefer von Zoe Srl - REA Milano n. MI-1835734 | Iscriz. Reg. Impr. Milano n. 05630890969",
      FR: "Kiefer von Zoe Srl - REA Milano n. MI-1835734 | Iscriz. Reg. Impr. Milano n. 05630890969"
    },
    {
      EN: "C.F e P. IVA IT05630890969 ",
      IT: "C.F e P. IVA IT05630890969 ",
      FR: "C.F e P. IVA IT05630890969 "
    },
    {
      EN: "Share capital entirely paid in € 10.000,00",
      IT: "Capitale Sociale € 10.000,00 <br/>Interamente Versato",
      FR: "Capital social de 10 000 euros entièrement libéré"
    }
  ]
};
