export const navbar = [
  { EN: "About us", IT: "Azienda", FR: "Enterprise", path: "/about-us" },
  { EN: "Latest", IT: "Novità", FR: "ACTUALITÉS", path: "/latest" },
  { EN: "Materials", IT: "Materiali", FR: "MATÉRIAUX", path: "/materials" },
  {
    EN: "Sustainability",
    IT: "Sostenibilità",
    FR: "DURABILITÉ ",
    path: "/sustainability"
  },
  // {
  //   EN: "KVZ consulting",
  //   IT: "KVZ consulting",
  //   FR:"KVZ consulting",
  //   path: "/kvz-consulting"
  // },
  // {
  //   EN: "CERTIFICATIONS",
  //   IT: "Certificazioni",
  //   FR: "CERTIFICATIONS",
  //   path: "/certifications"
  // },
  { EN: "Contacts", IT: "Contatti", FR: "CONTACTS", path: "/contacts" }
];
