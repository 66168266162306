<template>
  <nav
    id="main-navbar"
    :class="[
      'navbar navbar-expand-xl',
      { show: collapseNavbar, 'navbar-dark': !lightModeOn }
    ]"
  >
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand order-1 order-xl-0">
        <img
          v-if="lightModeOn"
          src="@/assets/full-logo-black.svg"
          alt="kiefer von zoe"
        />
        <img v-else src="@/assets/full-logo-white.svg" alt="kiefer von zoe" />
      </router-link>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="collapseNavbar = !collapseNavbar"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <div
        id="navbarNav"
        :class="[
          'collapse navbar-collapse order-1 order-xl-0',
          { show: collapseNavbar }
        ]"
      >
        <ul class="navbar-nav">
          <div class="navbar-nav--link">
            <router-link
              v-for="(navbarItem, index) in navbarData"
              :key="index"
              :to="navbarItem.path"
              :class="[
                'nav-item',
                {
                  active:
                    currentRoute
                      .toLowerCase()
                      .indexOf(navbarItem['EN'].toLowerCase()) !== -1
                }
              ]"
              @click="collapseNavbar = false"
            >
              {{ navbarItem[selectedLanguage] }}
            </router-link>
          </div>
          <!-- <a class="nav-item" href="#">Follow us</a> -->
          <kvz-navbar-item-dropdown title="Follow us" />
        </ul>
      </div>
      <div class="header-settings order-2">
        <kvz-theme-button />
        <div class="header-settings_language">
          <multiselect
            v-model="selectedLanguage"
            :options="languageOptions"
            :searchable="false"
            :close-on-select="true"
            :allow-empty="false"
            :show-labels="false"
            @select="changeLanguage"
          />
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { navbar } from "../crm/navbar";

import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      navbarData: navbar,
      collapseNavbar: false,
      selectedLanguage: localStorage.getItem("language") || "IT",
      value: "",
      languageOptions: ["IT", "FR", "EN"]
    };
  },
  computed: {
    lightModeOn() {
      return this.$store.state.values.activeTheme === "light-theme";
    },
    currentRoute() {
      return this.$route.fullPath;
    }
  },

  mounted() {
    const navbar = document.getElementById("main-navbar");
    const resizeObserver = new ResizeObserver(() => {
      if (navbar.clientWidth >= 1200) {
        this.collapseNavbar = false;
      }
    });
    resizeObserver.observe(navbar);
    if (localStorage.getItem("language")) {
      this.setLanguage(localStorage.getItem("language"));
    } else {
      this.setLanguage(this.selectedLanguage);
    }
  },
  methods: {
    changeLanguage(newLangualge) {
      this.setLanguage(newLangualge);
    },
    setLanguage(newLangualge) {
      localStorage.setItem("language", newLangualge);
      this.$store.dispatch("values/change-language", newLangualge);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "vue-multiselect/dist/vue-multiselect.css";
@import "@/scss/_color";
@import "@/scss/function";
.navbar {
  height: toRem(87);
  position: relative;
  border-bottom: 1px solid var(--primary-color);
  --bs-navbar-color: var(--primary-color);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-active-color: var(--primary-color);
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  &.show {
    border-bottom: none;
  }
  .navbar-brand {
    @media screen and (max-width: 1207px) {
      zoom: 0.9;
    }
  }
  :deep(.navbar-nav) {
    --bs-nav-link-color: var(--primary-color);
    --bs-nav-link-hover-color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
:deep(a) {
  color: var(--primary-color);
  text-decoration: none;
  margin: 0 toRem(12);
  font-style: normal;
  font-weight: 500;
  font-size: toRem(16);
  line-height: toRem(16);
  letter-spacing: toRem(0.8);
  position: relative;
  text-transform: uppercase;

  @media screen and (max-width: 1265px) {
    font-size: 0.9rem;
  }
  &:hover:not(.navbar-brand) {
    opacity: 80%;
    &::after {
      content: "";
      position: absolute;
      border-top: 2px solid var(--blue-color);
      left: 50%;
      transform: translate(-50%, 0);
      bottom: -4px;
      width: toRem(12);
      height: 0px;
    }
  }
}
.active:not(.navbar-brand),
.router-link-active:not(.navbar-brand) {
  font-weight: 700 !important;
  opacity: 1 !important;
  &::after {
    content: "";
    position: absolute;
    border-top: 2px solid var(--blue-color);
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -4px;
    width: toRem(32) !important; // override hover effect
    height: 0px;
  }
}
.collapse {
  transition: all 0.8s ease-in;
  &.show {
    position: absolute;
    z-index: 2;
    top: toRem(87);
    left: 0;
    width: 100%;
    background: var(--background-color-primary);
    .follow {
      margin: 0.5rem auto !important;
    }
    .navbar-nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid var(--primary-color);
      .nav-item {
        margin: 0.5rem 0;
      }
      &--link {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
.navbar-toggler {
  padding: 0.1rem 0.2rem;
  line-height: 1;
  color: var(--blue-color);
  background-color: transparent;
  border: 1px solid var(--blue-color);
  &:focus {
    box-shadow: 0 0 0 2px;
  }
}
.header-settings {
  display: flex;
  align-items: center;
  cursor: pointer;
}
//multiselect
.multiselect {
  color: var(--primary-color);
}
:deep(.multiselect__tags) {
  border: none;
  background: rgba(0, 0, 0, 0);
  width: 4rem;
}
:deep(.multiselect__input),
:deep(.multiselect__single) {
  background: rgba(0, 0, 0, 0);
  line-height: inherit;
}
:deep(.multiselect__content-wrapper) {
  background: var(--background-color-primary);
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid var(--secondary-color);
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}
:deep(.multiselect__option--highlight) {
  background: var(--blue-color);
  color: var(--primary-color);
}
.multiselect__option--highlight:after {
  background: var(--blue-color);
  color: var(--primary-color);
}
.multiselect__option--selected {
  background: var(--secondary-color);
  color: var(--primary-color);
  font-weight: 700;
}
.multiselect__option--selected:after {
  content: attr(data-selected);
  color: var(--secondary-color);
}
</style>
